import actions from './actions'
import claimActions from '../claim/actions'

const initState = {
  agentPolicyDetails: {},
  agencyBenefitList: {},
  successAgencyPolicy: false,
  successAgencyClaimList: false,
  agencyClaimList: [],
  agencyPolicyList: [],
  agencyMetadata: {}
}

export default function agency (state = initState, action) {
  switch (action.type) {
    case actions.RESET_AGENCY_STATE:
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        let stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state,
          ...initState
        }
      }
    case actions.AGENT_POLICY_DETAIL_SUCCESS:
      return {
        ...state,
        agentPolicyDetails: action.data
      }
    case actions.AGENT_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        agentPolicyDetails: {}
      }

    case actions.AGENT_POLICY_DETAILS_REQUEST:
      return {
        ...state
      }

    case actions.SUCCESS_AGENCY_CLAIM:
      return {
        ...state,
        claimId: action.claimId,
        createClaimStatus: action.createClaimStatus
      }

    case actions.AGENT_POLICY_DETAILS_SUCCESS:
      return {
        ...state,
        agentDetailsData: action.data,
        agentPolicyDetailResult: action.getAgentPolicyDetailResult
      }
    case actions.AGENT_POLICY_DETAILS_FAILURE:
      return {
        ...state,
        agentDetailsData: {},
        agentPolicyDetailResult: action.getAgentPolicyDetailResult
      }
    case actions.AGENCY_BENEFIT_LISTS:
      return {
        ...state,
        getAgencyBenefitListLoading: false,
        agencyBenefitList: action.agencyBenefitList,
        successAgencyBenefits: action.successAgencyBenefits
      }
    case actions.GET_AGENCY_BENEFITS:
      return {
        ...state,
        getAgencyBenefitListLoading: true
      }

    case actions.AGENCY_POLICY_LIST_SUCCESS:
      const fetchedPolicies = action.agencyPolicyList.data
      return {
        ...state,
        agencyPolicyList: fetchedPolicies,
        successAgencyPolicy: action.successAgencyPolicy,
        agencyMetadata: action.metadata
      }

    case actions.AGENCY_POLICY_LIST_FAILURE:
      return {
        ...state,
        agencyPolicyList: [],
        successAgencyPolicy: action.successAgencyPolicy
      }

    case actions.AGENCY_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        agencyClaimList: action.agencyClaimList,
        agencyMetadata: action.metadata,
        successAgencyClaimList: action.successAgencyClaimList
      }
    case actions.AGENCY_CLAIM_LIST_FAILURE:
      return {
        ...state,
        agencyClaimList: [],
        successAgencyClaimList: action.successAgencyClaimList
      }

    case actions.AGENCY_POLICY_LIST_START:
      return {
        ...state,
        successAgencyPolicy: action.successAgencyPolicy
      }

    case actions.AGENCY_POLICY_LIST:
      return {
        ...state
      }

    case claimActions.CLEAR_AGENCY_BENEFIT_LIST:
      return {
        ...state,
        agencyBenefitList: [],
        successAgencyBenefits: ''
      }

    default:
      return state
  }
}
